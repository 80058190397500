import axios from "axios";

export const validatePaymentCode = (paymentCode) => {
  return axios.get(
    `${process.env.PAYMENT_CODE_URL}?PaymentCode=${paymentCode}`,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": process.env.OCP_APIM_SUBSCRIPTION_KEY,
      },
    }
  );
};
