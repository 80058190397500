import * as Sentry from "@sentry/browser";
import { setLanguage, getLanguage, langs } from "./translations";
import { populateStatesSelect } from "./states";
import {
  getAmountWithCurrency,
  getAmount,
  validateForm,
  getPhone,
  populatePaymentKeys,
} from "./form";
import {
  handleErrorClassOnChange,
  enableInputs,
  togglePageLoader,
  toggleButtonLoader,
  removeErrors,
  addErrorToFields,
  fillForm,
  getInputFields,
  showErrorBox,
  removeErrorBox,
  showDelayAndRemoveBankRedirectionDialog,
} from "./htmlHelpers";
import { validatePaymentCode } from "../api/paymentCode";
import { getTicket } from "../api/ticketing";
import { getUserDetails } from "../api/user";
import { commodities } from "./commodities";
import { isAllowedUrl, isNullOrWhitespace } from "./validators";
import { isWebStorageSupported } from "./utils";

Sentry.init({
  dsn: process.env.SENTRY_KEY,
  release: process.env.npm_package_version,
  environment: process.env.ENV || process.env.NODE_ENV,
  ignoreErrors: [
    "Request aborted",
    "Error: Network Error",
    "TypeError: Cancelled",
    "TypeError: Failed to fetch",
    "timeout exceeded",
  ],
});

function invokePayment() {
  toggleButtonLoader(true);

  const inputsArr = [].slice.call(getInputFields());
  removeErrors(inputsArr);

  const formData = inputsArr.reduce((accumulator, currentValue) => {
    return { ...accumulator, [currentValue.id]: currentValue.value };
  }, {});

  if (isGuest === false && commodity && !isNullOrWhitespace(meterId)) {
    pay(formData);
    return;
  }
  meterId = null;
  commodity = null;
  validatePaymentCode(formData.PaymentCode)
    .then((res) => {
      if (res.data.Result === true) {
        commodity = res.data.Commodity;
        pay(formData);
        return;
      }
      addErrorToFields(["PaymentCode"]);
    })
    .catch((error) => {
      toggleButtonLoader(false);
      if (error.response?.data?.ResponseCode === 404) {
        addErrorToFields(["PaymentCode"]);
        return;
      }

      showErrorBox();
    });
}

function pay(formData) {
  const errorFields = validateForm(formData);
  if (errorFields.length > 0) {
    addErrorToFields(errorFields);
    toggleButtonLoader(false);

    return;
  }

  const jsonFormData = JSON.stringify({
    ...formData,
    CardholderName: null,
    token,
    accountId,
    createdAt: new Date(),
  });
  try {
    localStorage.setItem(process.env.STORAGE_KEY, jsonFormData);
  } catch (error) {
    const errorMessage = error?.message || "";
    /* catching to avoid QuotaExceeded Errors from being logged. 
    /* not setting data to local storage does not break the functionality of the page. user will just have to reenter the data on the form */
    if (errorMessage.toLowerCase().indexOf("quota") === -1) {
      Sentry.withScope((scope) => {
        Sentry.captureException(error);
      });
    }
  }

  const amount = getAmount(formData.Amount);
  const paymentCode = document.querySelector("#PaymentCode").value;

  const trackUnconfirmedPayment = isEna === false;
  //{cardholderName}|{amount}|{paymentCode}|{meterId}|{commodity}|{trackUnconfirmedPayment}|{invoiceNumber}
  const parameters = [
    formData.CardholderName,
    amount,
    paymentCode,
    meterId,
    commodity,
    trackUnconfirmedPayment,
    invoiceNo,
  ];
  try {
    if (paymentCode?.length !== 25) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          parameters: parameters,
          parametersDefinition:
            "{cardholderName}|{amount}|{paymentCode}|{meterId}|{commodity}|{trackUnconfirmedPayment}|{invoiceNumber}",
        });
        scope.setLevel("info");
        Sentry.captureMessage(
          "Payment page - Wrong Payment Code length in parameters",
        );
      });
    }
  } catch (error) {}

  try {
    if (paymentCode?.trim()?.length !== 25) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          parameters: parameters,
          parametersDefinition:
            "{cardholderName}|{amount}|{paymentCode}|{meterId}|{commodity}|{trackUnconfirmedPayment}|{invoiceNumber}",
        });
        scope.setLevel("info");
        Sentry.captureMessage(
          "Payment page - Wrong Payment Code length in parameters (TRIMMED)",
        );
      });
    }
  } catch (error) {}

  const data = {
    CurrencyCode: 978,
    BillAddrCity: formData.BillAddrCity,
    BillAddrCountry: formData.BillAddrCountry,
    BillAddrLine1: formData.BillAddrLine1,
    BillAddrPostCode: formData.BillAddrPostCode,
    BillAddrState: formData.BillAddrState,
    CardholderName: formData.CardholderName,
    Email: formData.Email,
    HomePhone: getPhone(formData.HomePhone),
    Amount: amount,
    Parameters: parameters.join("|"),
  };

  if (!commodity) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        data: JSON.stringify(_receivedData),
      });
      scope.setLevel("info");
      Sentry.captureMessage(
        "Payment invocation with empty commodity prevented",
      );
    });
    showErrorBox();
    toggleButtonLoader(false);
    return;
  }

  getTicket(data)
    .then((res) => {
      const response = res.data;
      const merchantRef = response ? response.MerchantReference : null;

      populatePaymentKeys(merchantRef, commodity);
      const lang = getLanguage();
      document.querySelector(
        "input[name=ParamBackLink]",
      ).value = `lang=${lang}`;
      document.querySelector("input[name=LanguageCode]").value =
        lang === "el" ? "el-GR" : "en-US";

      showDelayAndRemoveBankRedirectionDialog(submitForm);

      return;
    })
    .catch((error) => {
      showErrorBox();
    })
    .finally(() => {
      toggleButtonLoader(false);
    });
}

function submitForm() {
  document.querySelector("#redirection_form").submit();
}

function getBillDetails(token, accountId) {
  if (!token || token.length === 0) return;

  const storedData =
    isWebStorageSupported() && localStorage.getItem(process.env.STORAGE_KEY);

  if (storedData) {
    const _formData = JSON.parse(storedData);
    if (
      _formData.token &&
      _formData.token === token &&
      (!_formData.accountId || _formData.accountId === accountId) &&
      //use cached data for 15'
      (new Date() - new Date(_formData.createdAt)) / 1000 / 60 <= 15
    ) {
      fillForm(_formData);
      enableInputs();
      document.getElementById("terms").checked = false;
      return;
    }
    localStorage.removeItem(process.env.STORAGE_KEY);
  }

  togglePageLoader(true);

  // if you want to use the test endpoint use
  // "api/my-bill";

  getUserDetails(token, accountId)
    .then((res) => {
      // Fill the form
      fillForm(res.data);
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        // no need to log 401 unauthorized errors
        return;
      }
    })
    .then(() => {
      togglePageLoader(false);
      enableInputs();
    });
}

// Wait for client to send paymentCode: ‘’, amount: '', token: '', guest:
let isEna = false;
let isGuest = true;
let token = null;
let accountId = null;
let meterId = null;
let commodity = null;
let isSettlement = false;
let invoiceNo = null;
let _receivedData = null;
window.addEventListener(
  "message",
  (event) => {
    if (isAllowedUrl(event.origin)) {
      _receivedData = event.data;
      if (
        typeof event.data.guest !== "undefined" ||
        typeof event.data.token !== "undefined"
      ) {
        isGuest = event.data.guest === true;
        token = event.data.token;
        accountId = event.data.accountId;
        isEna = event.data.isEna === true;
        isSettlement = event.data.fixedAmount === true;
        if (isGuest === true) {
          document.querySelector("#PaymentCode").disabled = false;
          document.querySelector("#Amount").disabled = false;
          enableInputs();
          togglePageLoader(false);
          meterId = null;
          commodity = null;
        } else if (token) {
          if (event.data.paymentCode) {
            try {
              if (event.data.paymentCode?.length !== 25) {
                Sentry.withScope((scope) => {
                  scope.setExtras({
                    postData: JSON.stringify(event.data),
                  });
                  scope.setLevel("info");
                  Sentry.captureMessage(
                    "Payment page - Wrong Payment Code received",
                  );
                });
              }
            } catch (error) {}
            document.querySelector("#PaymentCode").value =
              event.data.paymentCode;
            document.querySelector("#PaymentCode").disabled = true;
          } else {
            document.querySelector("#PaymentCode").disabled = false;
          }

          if (event.data.amount) {
            document.querySelector("#Amount").value = getAmountWithCurrency(
              event.data.amount,
            );
          }

          if (isEna) {
            document.querySelector("#ena_info").style.display = "block";
          }
          if (event.data.meterId) {
            meterId = event.data.meterId;
          }

          if (event.data.invoiceNo) {
            invoiceNo = event.data.invoiceNo;
          }
          document.querySelector("#Amount").disabled =
            event.data.fixedAmount === true;

          if (event.data.commodity) {
            commodity =
              `${event.data.commodity}` === `${commodities.gas}`
                ? commodities.gas
                : commodities.energy;
          } else {
            Sentry.withScope((scope) => {
              scope.setExtras({
                postData: JSON.stringify(_receivedData),
              });
              scope.setLevel("info");
              Sentry.captureMessage(
                "Payment page - non guest payment with empty commodity",
              );
            });
          }

          getBillDetails(token, accountId);
        }

        const lang = getLanguage();
        document.querySelector('label[for="Amount"]').innerHTML = isEna
          ? langs[lang].participation
          : langs[lang].debtAmount;
      }
    }
  },
  false,
);

window.addEventListener("load", function () {
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  setLanguage();
  populateStatesSelect();
  document.querySelectorAll('input[type="text"]').forEach((x) => {
    handleErrorClassOnChange(x);
  });

  // Notify the client that the form has loaded
  window.parent.postMessage("Payment gateway initialised.", "*");
  if (window.callbackHandler) {
    window.callbackHandler.completedSuccessfully(
      "Payment gateway initialised.",
    );
  }

  try {
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.completedSuccessfully
    ) {
      window.webkit.messageHandlers.completedSuccessfully.postMessage(
        "Payment gateway initialised.",
      );
    }
  } catch (error) {
    Sentry.withScope(function (scope) {
      scope.setExtras({ snippet: "window.webkit" });
      Sentry.captureException(error);
    });
  }

  document
    .querySelector(".close-icon")
    .addEventListener("click", removeErrorBox);

  const checkbox = document.querySelector("input[type=checkbox]");
  const submit = document.querySelector("button[type=submit]");
  checkbox.addEventListener("change", function () {
    submit.disabled = !this.checked;
  });

  const form = document.getElementById("form");
  form.addEventListener("submit", function (event) {
    event.preventDefault();
    invokePayment();
  });
});
